import { useContext } from 'react';
import { LanguageContext } from '../contexts/language-context';
import { ptBr, enUs } from '../lang/index';

export function useTranslation(){
  const {lang, setLang} = useContext(LanguageContext)
  
  const translate = (key) => {
    switch(lang){
      case 'pt':
        return ptBr[key]
      case 'en':
        return enUs[key]
      default:
        return key
    }
  }

  return {
    lang,
    setLang,
    translate,
  }
}