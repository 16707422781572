export const ptBr = {
  "WELCOME_DESCRIPTION": "<h1>Software Developer</h1>",
  "WELCOME_DESCRIPTION_2": "<h1>Computer Science Student</h1>",
  "WELCOME_DESCRIPTION_3": "<h1>Tech Content Creator</h1>",
  "ABOUT_ME_TITLE": "Olá! Eu sou a Fernanda",
  "ABOUT_ME_DESCRIPTION": "Apaixonada pelo desenvolvimento web desde os 18 anos, brasileira e nascida em 2001. Sou estudante de Ciência da Computação na Universidade Federal de Pelotas e Engenheira de Software no maior banco da América Latina. Sou uma pessoa que adora desafios, assim como todo desenvolvedor, mas em especial aqueles que me tiram da minha zona de conforto, pois as minhas maiores evoluções e aprendizados vierem de momentos em que me vi obrigada a sair dela e enfrentar a vida. Estudante assídua, que aprecia tomar café e nas horas vagas compartilha conhecimento nas redes sociais. Assisto romances e séries investigativas e também adoro ir na academia 😊",
  "ABOUT_ME": "Sobre Mim",
  "XP": "Experiência",
  "PROJECTS": "Projetos",
  "POSTS": "Leituras rápidas",
  "LIKES": "Reações",
  "DATE": "Publicado em",
  "MY_EXPERIENCE": "Minha experiência",
  "AFTERSALE_TRAINEE_TITLE": "Frontend Tech Trainee na Aftersale",
  "AFTERSALE_TRAINEE_DESCRIPTION": "Como Frontend Trainee, auxiliei na manutenção e refatoração de projetos que impactaram na melhora da qualidade e legibilidade do sistema e junto aos meus colegas conseguimos reduzir á zero o número de bugs Frontend em backlog. Além disso, auxiliei na escrita da documentação de fluxos da aplicação.",
  "AFTERSALE_TRAINEE_DATE": "Março 2021 - Novembro 2021",
  "SCHOLARSHIP_TITLE": "Bolsista de Desenvolvimento Tecnológico pela FAPERGS",
  "SCHOLARSHIP_DESCRIPTION": "Como bolsista da <a href='https://fapergs.rs.gov.br/inicial'>Fundação de Amparo à Pesquisa do Estado do Rio Grande do Sul</a> na Universidade Federal de Pelotas (UFPEL), contribui desde a etapa da concepção da ideia até o desenvolvimento de um indexador digital para Recursos Educacionais Abertos, o qual trará para a comunidade acadêmica um grande impacto positivo nas questões de reaproveitamento e universalização desses materiais. O estudo foi publicado em conferências de pesquisa e educação como a <a href='https://www.laclolala.com/#/laclo'>Conferência Latino-americana de Tecnologias</a> de Aprendizagem e em duas edições da <a href='https://wp.ufpel.edu.br/congressoinovacao/sobre/'>Semana Integrada de Inovação da UFPEL</a>. A ferramenta está em desenvolvimento e fase de testes com docentes locais, e foi construída utilizando conhecimentos de <b>JavaScript, NodeJS e React JS.</b>",
  "SCHOLARSHIP_DATE": "Agosto 2020 - Agosto 2021",
  "AFTERSALE_DEV_TITLE": "Desenvolvedora Frontend Júnior na Aftersale",
  "AFTERSALE_DEV_DESCRIPTION": `A <a href='https://after.sale/' target='_blank'>Aftersale</a> é uma startup que oferece soluções inteligentes e automatizadas de pós venda para e-commerces, que auxiliam na fidelização e retenção dos consumidores. 
  </br>
  Como desenvolvedora Frontend minhas responsabilidades incluíam desenvolvimento de novas features, escrita de testes unitários, code review, resolução de chamados, refatorações e migração de arquitetura. Além da atuação em projetos backend desenvolvidos em  GraphQL e Node JS.
  </br>
  </br>
  <b>Tecnologias:</b> HTML, CSS, Javascript, Typescript, ReactJS, NextJs, GraphQL, Node Js, Micro serviços, Jest, Testing Library, Google Analytics, Google Tag Manager.`,
  "AFTERSALE_DEV_DATE": "Novembro 2021 - Março 2022",
  "STONE_TITLE": "Desenvolvedora Frontend na Stone",
  "STONE_DESCRIPTION": `A <a href='https://www.stone.com.br/' target='_blank'>Stone</a> é uma fintech brasileira de meios de pagamentos, terceira maior adquirente do Brasil. Pertencia ao time de Marketing & Growth, onde nossos maiores objetivo eram aquisição, retenção e encantar os clientes Stone. 
  </br>
  Como desenvolvedora Frontend minhas responsabilidades incluíam implementação de features nos sites institucionais da empresa e manutenção dos mesmos. 
  </br>
  </br>
  <b>Tecnologias: </b>HTML, CSS, Javascript, Typescript, Jquery, ReactJS, NextJs, Google Optmize, Google Analytics.`,
  "STONE_DATE": "Março 2022 - Julho 2022",
  "ITAU_TITLE": "Engenheira de Software no Itaú Unibanco",
  "ITAU_DESCRIPTION": `O <a href='https://www.itau.com.br/' target='_blank'>Itaú</a> é o maior banco privado da América Latina, com mais de 5 mil agências espalhadas pela américa e impactando diariamente a vida de milhões de pessoas. Atuo na squad de Jornada Personalizada que pertence a comunidade de Onboarding e Abertura de Contas, somos a porta de entrada do cliente no banco, responsáveis pela jornada de abertura de conta e onboarding dos nossos clientes, e nosso objetivo é oferecer o melhor onboarding do mercado.
  </br>
  Como Engenheira de Software minhas responsabilidades incluem desenvolvimento de novas features, code review, escrita de testes unitários, resolução de chamados e bugs, monitoramento e deploy da aplicação.
  </br>
  </br>
  <b>Tecnologias:</b> Typescript, Javascript, Angular, Micro Frontends, Micro Seviços, Angular, Git, AWS Services, Google Analytics.`,
  "ITAU_DATE": "Julho 2022 - Hoje",
  "DESCRIPTION_LINK_PAGE": "Oi, sou a Fe! Aqui você vai encontrar conteúdos tech feitos com muito carinho 😊",
  "YOUTUBE_SECTION_TITLE": "Último vídeo do canal 👇🏼",
  "DISCOUNT_SECTION_TITLE": "Cupons e indicações!",
  "EVENTS_SECTION_TITLE": "Eventos Tech com desconto!",
  "YOUTUBE_LOADING_VIDEO": "Carregando o último vídeo do canal...",
}

export const enUs = {
  "WELCOME_DESCRIPTION": "<h1>Software Developer</h1>",
  "WELCOME_DESCRIPTION_2": "<h1>Computer Science Student</h1>",
  "WELCOME_DESCRIPTION_3": "<h1>Tech Content Creator</h1>",
  "ABOUT_ME_TITLE": "Hi There! I´m Fernanda",
  "ABOUT_ME_DESCRIPTION": "Passionate about web development since I was 18 years old, Brazilian and born in 2001. I am a Computer Science student at the Federal University of Pelotas and a Software Engineer at the largest bank in Latin America. I'm a person who loves challenges, like every developer, but especially those that take me out of my comfort zone, because my biggest evolutions and learnings come from moments when I was forced to leave it and face life. Assiduous student, who enjoys drinking coffee and in her spare time shares knowledge on social networks. I love watching netflix series and I also enjoy going to the gym 😊",
  "ABOUT_ME": "About me",
  "XP": "Experience",
  "PROJECTS": "Projects",
  "POSTS": "Posts on Dev.To",
  "LIKES": "Reactions",
  "DATE":"Publication date",
  "MY_EXPERIENCE": "My work experience",
  "AFTERSALE_TRAINEE_TITLE": "Frontend Tech Trainee at Aftersale",
  "AFTERSALE_TRAINEE_DATE": "March 2021 - November 2021",
  "AFTERSALE_TRAINEE_DESCRIPTION": "As a Frontend Trainee, I helped in the maintenance and refactoring of applications that impact more then 50 thousand monthly users. Besides, i helped on the improving the quality and readability of the system and, together with my colleagues, we managed to reduce the number of Frontend bugs in the backlog to zero. In addition, I assisted in writing the application flow documentation.",
  "SCHOLARSHIP_TITLE": "Technological Development Scholarship by FAPERGS",
  "SCHOLARSHIP_DESCRIPTION": "As a scholarship holder at the <a href='https://fapergs.rs.gov.br/inicial'>Research Support Foundation of the State of Rio Grande do Sul</a> at the Federal University of Pelotas, I contributed to the conception of the idea and development of a digital indexer for Open Educational Resources, which will bring to the academic community a great positive impact on the issues of reuse and universalization of these materials. These study was published at research and educational conferences such as <a href='https://www.laclolala.com/#/laclo'>Conferência Latino-americana de Tecnologias</a> de Aprendizagem e em duas edições da <a href='https://wp.ufpel.edu.br/congressoinovacao/sobre/'>Semana Integrada de Inovação da UFPEL</a>. The tool is in development and testing phase with local teachers, and was built using knowledge of <b>JavaScript, NodeJS and React JS.</b>",
  "SCHOLARSHIP_DATE": "August 2020 - August 2021",
  "AFTERSALE_DEV_TITLE": "Junior Frontend Developer at Aftersale",
  "AFTERSALE_DEV_DESCRIPTION": `<a href='https://after.sale/' target='_blank'>Aftersale</a> is a startup that offers intelligent and automated after-sales solutions for e-commerces, which help in customer loyalty and retention. consumers.
  </br>
  As a Frontend developer my responsibilities included developing new features, writing unit tests, code review, ticket resolution, refactorings and architecture migration. I´ve also worked on backend projects developed with GraphQL and Node JS.
  </br>
  </br>
  <b>Technologies:</b> HTML, CSS, Javascript, Typescript, ReactJS, NextJs, GraphQL, Node Js, Microservices, Jest, Testing Library, Google Analytics, Google Tag Manager.`,
  "AFTERSALE_DEV_DATE": "November 2021 - March 2022",  
  "STONE_TITLE": "Frontend Developer at Stone",
  "STONE_DESCRIPTION": `<a href='https://www.stone.com.br/' target='_blank'>Stone</a> is a Brazilian payment means fintech, the third largest acquirer in Brazil. I belonged to the Marketing & Growth team, where our main objectives were to acquire, retain and delight Stone customers.
  </br>
  As a Frontend developer my responsibilities included implementing features on the company's institutional websites and maintaining them.
  </br>
  </br>
  <b>Technologies: </b>HTML, CSS, Javascript, Typescript, Jquery, ReactJS, NextJs, Google Optimize, Google Analytics.`,
  "STONE_DATE": "March 2022 - July 2022",
  "ITAU_TITLE": "Junior Software Engineer",
  "ITAU_DESCRIPTION": `<a href='https://www.itau.com.br/' target='_blank'>Itaú</a> is the largest private bank in Latin America, with over 5,000 branches spread across America and impacting the lives of millions of people daily. I work on Onboarding and Account Opening community, we are the customer's gateway to the bank, responsible for the account opening journey and onboarding of our customers, and our main goal is to offer the best onboarding from the market .
  </br>
  As a Software Engineer, my responsibilities include developing new features, code review, writing unit tests, fixing bugs, monitoring and deploying the application.
  </br>
  </br>
  <b>Technologies:</b> Typescript, Javascript, Angular, Micro Frontends, Micro Seviços, Angular, Git, AWS Services, Google Analytics.`,
  "ITAU_DATE": "July 2022 - Today",
  "DESCRIPTION_LINK_PAGE": "Hi, I'm Fe! Here you will find tech content made with great care 😊",
  "YOUTUBE_SECTION_TITLE": "Check out the latest video on the channel 👇🏼",
  "DISCOUNT_SECTION_TITLE": "Coupons and indications!",
  "EVENTS_SECTION_TITLE": "Tech events with discount!",
  "YOUTUBE_LOADING_VIDEO": "Loading last channel's video...",
}