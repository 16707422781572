import { useState, createContext, ReactNode } from 'react';

interface LanguageContextStruct {
  lang: string,
  setLang: (lang: string) => void,
}

interface LanguageProviderProps {
  children: ReactNode
}

export const LanguageContext = createContext({} as LanguageContextStruct);

export function LanguageContextProvider({children} : LanguageProviderProps){
  const [lang, setLang] = useState('pt');

  return(
    <LanguageContext.Provider value={{lang, setLang}}>
      {children}
    </LanguageContext.Provider>
  )
};