import './styles/App.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LanguageContextProvider } from './contexts/language-context';
import { Home } from './pages/home';
import { LinkPage } from './pages/link';
import { SetupAnalytics } from './components/setup-analytics';
import { Redirect} from './redirect';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LinkPage/>,
  },
  {
    path: "/links/*",
    element: <LinkPage/>,
  },
  {
    path: "/*",
    element: <Redirect/>,
  }
]);

function App() {
  const client = new QueryClient()

  return (
    <LanguageContextProvider>
      <QueryClientProvider client={client}>
        <RouterProvider router={router} />
        <SetupAnalytics/>
      </QueryClientProvider>
    </LanguageContextProvider>
  );
}

export default App;
