export function LinkedinIcon(){
    return(
        <svg 
            id="linkedin"
            width="40" 
            height="40" 
            viewBox="0 0 40 40" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path id="linkedin" d="M9.31097 12.8684H1.2809C0.924516 12.8684 0.635742 13.1573 0.635742 13.5136V39.3107C0.635742 39.6671 0.924516 39.9559 1.2809 39.9559H9.31097C9.66735 39.9559 9.95613 39.6671 9.95613 39.3107V13.5136C9.95613 13.1573 9.66735 12.8684 9.31097 12.8684Z" fill="#F5f5f5f5"/>
            <path id="linkedin" d="M5.29884 0.0440063C2.37703 0.0440063 0 2.41846 0 5.33704C0 8.25691 2.37703 10.6323 5.29884 10.6323C8.21832 10.6323 10.5934 8.25678 10.5934 5.33704C10.5935 2.41846 8.21832 0.0440063 5.29884 0.0440063Z" fill="#F5f5f5f5"/>
            <path id="linkedin" d="M29.736 12.2272C26.5108 12.2272 24.1267 13.6137 22.6806 15.189V13.5136C22.6806 13.1573 22.3919 12.8684 22.0355 12.8684H14.3453C13.9889 12.8684 13.7001 13.1573 13.7001 13.5136V39.3107C13.7001 39.6671 13.9889 39.9559 14.3453 39.9559H22.3578C22.7142 39.9559 23.003 39.6671 23.003 39.3107V26.5471C23.003 22.2461 24.1712 20.5705 27.1694 20.5705C30.4347 20.5705 30.6942 23.2567 30.6942 26.7684V39.3108C30.6942 39.6672 30.983 39.956 31.3394 39.956H39.3548C39.7112 39.956 40 39.6672 40 39.3108V25.1607C40 18.7652 38.7805 12.2272 29.736 12.2272Z" fill="#F5f5f5f5"/>
        </svg>
    )
}