export function EmailIcon(){
    return(
        <svg 
            id="email"
            width="20" 
            height="20" 
            viewBox="0 0 42 42" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M37.8 4.2H4.2C1.88024 4.2 0 6.08024 0 8.4V33.6C0 35.9198 1.88024 37.8 4.2 37.8H37.8C40.1198 37.8 42 35.9198 42 33.6V8.4C42 6.08024 40.1198 4.2 37.8 4.2ZM2.1 12.2017L14.6692 21L2.1 29.799V12.2017ZM39.9 33.6C39.9 34.7578 38.9578 35.7 37.8 35.7H4.2C3.04221 35.7 2.1 34.7578 2.1 33.6V32.361L16.5004 22.281L20.3973 25.0089C20.762 25.263 21.2381 25.263 21.6034 25.0089L25.5003 22.281L39.9 32.361V33.6ZM39.9 29.7983L27.3308 21L39.9 12.201V29.7983ZM39.9 9.639L21 22.8683L2.1 9.639V8.4C2.1 7.24221 3.04221 6.3 4.2 6.3H37.8C38.9578 6.3 39.9 7.24221 39.9 8.4V9.639V9.639Z" 
                fill="#F5f5f5f5" 
                stroke="#F5f5f5f5"
                id="email"
            />
        </svg>
    )   
}