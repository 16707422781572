import styled, { keyframes, css } from "styled-components";
import ReactGA from 'react-ga4';

import { colors } from '../../theme.config';
import { ReactNode } from "react";
import { color } from "@chakra-ui/react";

interface Props {
  title: string,
  link: string,
  subtitle: string,
  analyticsLabel: string
}

const Button = styled.a`
    border: 1px solid ${colors.textDark};
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    max-width: 700px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    text-decoration: none;

    svg {
        position: absolute;
        left: 8px;
        height: 40px;
        width: 40px;
    }

    img {
        position: absolute;
        left: 8px;
        height: 40px;
        width: 40px;
        border-radius: 100%;
    }

    p {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 100%;
        margin-left: 8px;

        span {
          font-weight: 300;
          font-size: 12px;
        }

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 24px;

          span {
            font-size: 14px;
          }
        }
    }

    &:hover {
      background-color: ${colors.textDark};
    }
`

export function SecondaryLink({ title, link, subtitle, analyticsLabel }: Props){
    const trackClick = () => {
        ReactGA.event({
            label: analyticsLabel,
            category: "Clique",
            action: "Clique"
        })
    }

    return(
        <Button 
            href={link} 
            target="_blank" 
            onClick={trackClick} 
            id={analyticsLabel}
        >
          <p>
            {title}
            <span>
              {subtitle}
            </span>
          </p>
        </Button>
    )
}