import { useEffect } from "react";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

export function SetupAnalytics(){
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-T4R4XVF" });
    ReactGA.initialize("G-N8GYWCN299");
  },[])

  return null;
}