import { ReactNode } from "react"
import styled from "styled-components"
import { colors } from '../../theme.config';
import { useTracking } from "../../hooks/use-tracking";

interface Props {
    title: string,
    subtitle: string,
    icon?: ReactNode,
    link: string,
    analyticsLabel: string
}

const Button = styled.a`
    background: linear-gradient(90deg, ${colors.primary} -6.31%, ${colors.secondary} 102.58%);
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    max-width: 700px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: relative;
    cursor: pointer;

    text-decoration: none;

    svg {
        position: absolute;
        left: 8px;
        height: 40px;
        width: 40px;
    }

    img {
        position: absolute;
        left: 8px;
        height: 40px;
        width: 40px;
        border-radius: 100%;
    }

    p {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 100%;
        margin-left: 8px;

        span {
            font-weight: 300;
        }

        @media (min-width: 768px) {
            font-size: 16px;
            line-height: 24px;
        }
    }

    @keyframes buzz {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }

    animation: buzz 1s 2.5s ease-in both infinite;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.02);
        animation: none;
    }
`

export function PrimaryLink({ icon, subtitle, title, link, analyticsLabel }: Props) {
    const { trackClick } = useTracking();

    return (
        <Button
            href={link}
            target="_blank"
            onClick={() => trackClick(analyticsLabel)}
            id={analyticsLabel}
        >
            {icon}
            <p>
                {title}
                <span>{subtitle}</span>
            </p>
        </Button>
    )
}