import ReactGA from 'react-ga4';

export function useTracking(){
    const trackClick = (analyticsLabel: string) => {
        ReactGA.event({
            label: analyticsLabel,
            category: "Clique",
            action: "Clique",
        })
    }

    return { trackClick }
}